<template>
  <div>
    <app-toolbar :link="link" :logo="logo" :primary="primary" :secondary="secondary" :value="drawer" @updated="drawerState($event)" :is-home="isHome" />
    <app-drawer :value="drawer" @updated="drawerState($event)" />
  </div>
</template>

<script>
  import Toolbar from '@/components/Toolbar.vue'
  import Drawer from '@/components/Drawer.vue'

  export default {
    props: {
      archive: {
        type: Boolean,
        default: false
      },
      category: {
        type: String,
        default: 'guido'
      },
      single: {
        type: Boolean,
        default: false
      },
      isHome: {
        // type: Boolean,
        default: false
      }
    },
    components: {
      'AppToolbar': Toolbar,
      'AppDrawer': Drawer
    },
    data: () => ({
      drawer: false,
      links: {
        guido: '/',
        media: '/media-guido',
        globo: '/globo-guido',
        gallery: '/gallery-guido',
        gaia: '/gaia-fawkes',
        guynews: '/guynews'
      },
      logos: {
        guido: require('@/assets/logo-guido-original.svg'),
        media: require('@/assets/logo-media.svg'),
        globo: require('@/assets/logo-globo.svg'),
        gallery: require('@/assets/logo-gallery.svg'),
        gaia: require('@/assets/logo-gaia.svg'),
        guynews: require('@/assets/logo-guynews-alt.svg'),
        guido_alt: require('@/assets/logo-guido-alt.svg'),
        guynews_alt: require('@/assets/logo-guynews.svg'),
        guido_original: require('@/assets/logo-guido.svg')
        // guido: 'https://order-dev-2019.mystagingwebsite.com/wp-content/themes/gfx/assets/logo-final-guido.svg' , //require('@/assets/logo-guido.svg'),
        // media: 'https://order-dev-2019.mystagingwebsite.com/wp-content/themes/gfx/assets/logo-final-media.svg' , //require('@/assets/logo-media.svg'),
        // euro: 'https://order-dev-2019.mystagingwebsite.com/wp-content/themes/gfx/assets/logo-final-euro.svg' , //require('@/assets/logo-euro.svg'),
        // gallery: 'https://order-dev-2019.mystagingwebsite.com/wp-content/themes/gfx/assets/logo-final-gallery.svg' , //require('@/assets/logo-gallery.svg'),
        // gaia: 'https://order-dev-2019.mystagingwebsite.com/wp-content/themes/gfx/assets/logo-final-gaia.svg' , //require('@/assets/logo-gaia.svg'),
        // guynews: 'https://order-dev-2019.mystagingwebsite.com/wp-content/themes/gfx/assets/logo-final-guynews.svg' , //require('@/assets/logo-guynews.svg')
      },
      colors: {
        guido: {
          primary: 'red accent-4',
          secondary: 'red darken-4'
        },
        media: {
          primary: 'grey',
          secondary: 'grey darken-1'
        },
        globo: {
          primary: 'blue darken-3',
          secondary: 'blue darken-4'
        },
        gallery: {
          primary: 'green darken-3',
          secondary: 'green darken-4'
        },
        gaia: {
          primary: 'green accent-3',
          secondary: 'green accent-4'
        },
        guynews: {
          primary: 'indigo darken-3',
          secondary: 'indigo darken-4'
        },
      }
    }),
    computed: {
      link() {
        return this.single ? this.links[this.category] : '/'
      },
      logo() {
        return this.logos[this.category]
      },
      primary() {
        return this.colors[this.category].primary
      },
      secondary() {
        return this.colors[this.category].secondary
      }
    },
    methods: {
      drawerState(state) {
        this.drawer = state
      }
    }
  }
</script>