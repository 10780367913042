// import 'babel-polyfill'
import Vue from 'vue'
// import App from './App.vue'
import vuetify from './plugins/vuetify'

require('es6-promise').polyfill()
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

// import '@/components/bootstrap'
Vue.component('app-footer', require('./components/Footer.vue').default)
Vue.component('app-header', require('./components/Header.vue').default)
Vue.component('app-menu', require('./components/Menu.vue').default)

Vue.component('index-category', require('./components/Category.vue').default)
Vue.component('index-post', require('./components/Post.vue').default)

Vue.component('seen-elsewhere', require('./components/SeenElsewhere.vue').default)
Vue.component('quote-of-the-day', require('./components/QuoteOfTheDay.vue').default)
Vue.component('guidogram', require('./components/Guidogram.vue').default)

Vue.component('ad-footer', require('./components/AdFooter.vue').default)

// Vue.component('disqus-comments', require('./components/DisqusComments.vue').default)

// Vue.component('ad-sidebar', require('./components/AdSidebar.vue').default)
// new Vue({
//   vuetify,
//   el: '#app',
//   template:"<app></app>",
//   data: {
//     // 
//   },
//   components: {
//     App
//   }
// })
new Vue({
  vuetify,
  el: "#app"
})
// new Vue({
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')
