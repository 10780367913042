<template>
  <div></div>
</template>

<script>
  export default {
    name: 'CategoryIndex',

    props: {
      data: Object
    }
  }
</script>