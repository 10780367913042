<template>
  <v-navigation-drawer
    v-model="drawer"
    @input="updateDrawer($event)"
    app
    right
    temporary
    fixed
    class="nav-drawer"
  >
    <v-list two-line dark class="py-0"></v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'Menu',

    props: {
      value: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      drawer: {
        get() {
          return this.value
        },
        set(val) {
          return val
        }
      }
    },

    methods: {
      updateDrawer(state) {
        this.$emit('updated', state)
      }
    }
  }
</script>