<template>
  <v-card :color="color" class="mb-6 quote-of-the-day">
    <v-card-title class="white--text font--anton py-3">
      <!-- <v-icon color="white" class="mr-2">mdi-comment-quote</v-icon> -->
      <a href="/quote-of-the-day" style="color:inherit;text-decoration:none;">
      <v-icon dark class="mr-2">mdi-comment-quote</v-icon> Quote of the Day
      </a>
      <v-spacer></v-spacer>
      <v-btn icon :href="twShare" target="_blank" rel="noopener">
        <v-icon dark>mdi-twitter</v-icon>
      </v-btn>
      <v-btn icon :href="fbShare" target="_blank" rel="noopener">
        <v-icon dark>mdi-facebook</v-icon>
      </v-btn>
    </v-card-title>
    <transition name="slide-y-transition" mode="out-in">
    <v-card-text class="amber lighten-5 pt-6" v-html="quote"></v-card-text>
    </transition>
  </v-card>
</template>

<script>
export default {
  name: 'QuoteOfTheDay',

  props: {
    category: {
      type: String,
      default: 'guido'
    }
  },

  data: () => ({
    quote: '',
    link: '',
    title: '',
    categories: {
      guido: {
        color: 'red accent-4',
        title: 'Seen Elsewhere',
        value: 0
      },
      media: {
        color: 'grey',
        title: 'Media News List',
        value: 1
      },
      globo: {
        color: 'blue darken-3',
        title: 'Global News',
        value: 2
      },
      gallery: {
        color: 'green darken-3',
        title: 'Sketch Round-Up',
        value: 3
      },
      gaia: {
        color: 'green accent-3',
        title: 'Green News',
        value: 4
      },
      guynews: {
        color: 'indigo darken-3',
        title: 'Seen Elsewhere',
        value: 5
      }
    }
  }),

  computed: {
    cat() {
      switch (this.catId) {
        case 1:
          return '8,15' //'15'
        case 2:
          return '2,21' //'21'
        case 3:
          return '4,29' //'29'
        case 4:
          return '3,23' //'23'
        case 5:
          return '6'
        default:
          return '5'
      }
    },
    catId() {
      return this.categories[this.category].value
    },
    color() {
      return this.categories[this.category].color
    },
    fbShare() {
      return "https://www.facebook.com/sharer.php?u="+this.link
    },
    twShare() {
      return "https://twitter.com/intent/tweet?url="+this.link+"&text="+this.title+"&via=guidofawkes"
    }
  },

  methods: {
    getQuote() {
      this.$axios.get('/wp-json/wp/v2/quote?filter[orderby]=date&order=desc&per_page=1&categories=' + this.cat)
      .then(response => {
        this.quote = response.data[0].content.rendered
        this.link = response.data[0].link
        this.title = response.data[0].seo_title ? response.data[0].seo_title : response.data[0].title.rendered
      }).catch(error => {
        this.errors = error
      })
    },
  },

  mounted() {
    this.getQuote();
  }
}
</script>
