<template>
  <v-footer absolute dark app color="blue-grey darken-4">
    <v-row align="center">
      <v-col cols="12" sm="12" md="6" lg="6">
        <p class="blue-grey--text caption text-center text-md-left mb-0">
          Owned &amp; Published by the Guido Fawkes Organisation. All
          Rights Reserved
          <v-icon x-small color="blue-grey">mdi-copyright</v-icon> {{ currentYear }}
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" class="text-center text-md-right">
        <v-btn
          v-for="(item, i) in items"
          :key="i"
          :href="item.slug"
          small
          text
          tile
          color="blue-grey lighten-4"
          :target="item.blank ? '_blank' : ''"
          :rel="item.blank ? 'noopener' : ''"
        >
          {{ item.name }}
        </v-btn>
        <!-- <v-btn @click="reopenConsent" small text tile color="blue-grey lighten-4">Ad Choices</v-btn> -->
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="isScrolled" ref="adSheet" persistent hide-overlay inset eager no-click-animation content-class="v-footer--sticky-ad mb-3">
      <v-card tile color="transparent" class="d-flex" flat>
        <div class="d-flex text-center align-center justify-center mx-auto sticky-ad__wrapper">
          <slot name="ad-footer"></slot>
        </div>
        <!-- <v-spacer></v-spacer> -->
        <div class="d-flex align-center justify-end" style="margin-left: -52px;">
          <v-btn small text icon class="ma-3 white" color="blue-grey darken-4" @click="sheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-bottom-sheet>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs && this.sheet && this.scrolled
    },
    isScrolled() {
      return this.sheet && this.scrolled
    }
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      items: [
        { name: 'About', slug: '/about' },
        { name: 'Advertising', slug: 'https://messagespace.co.uk', blank: true },
        // { name: 'Contact', slug: '/contact' },
        { name: 'Privacy', slug: '/privacy' },
        { name: 'Tip-Off', slug: '/tips' }
      ],
      scrolled: false,
      sheet: true
    }
  },

  watch: {
    isScrolled() {
      this.$nextTick(() => {
        this.$refs.adSheet.showScroll();
        // either set :retain-focus="false" above or do this:
        this.$nextTick(() => this.$refs.adSheet.unbind());
      });
    }
  },

  methods: {
    handleScroll () {
      this.scrolled = window.scrollY > 360;
    },
    // reopenConsent() {
    //   window.__tcfapi('displayConsentUi',2, function() {});
    // }
  },

  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>