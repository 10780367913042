<template>
  <v-card :color="color" class="mb-6 quote-of-the-day">
    <v-card-title class="white--text font--anton py-3">
      <v-icon dark class="mr-2">mdi-email</v-icon> Guidogram</v-card-title>
<!--     <v-card-text class="amber lighten-5 py-0">
      <v-text-field
        color="blue-grey darken-3"
        placeholder="name@domain.com"
      ></v-text-field>
    </v-card-text> -->
    <v-card-text class="amber lighten-5 pt-4">
    <!-- Mailchimp -->
    <div id="mc_embed_signup my-1">
      <form action="https://order-order.us1.list-manage.com/subscribe/post?u=84f2eb51bf3db29fcbf92aaf0&amp;id=547885726c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="form-group validate" target="_blank" novalidate>
      <div class="">
        <p>Subscribe to the most succinct 7 days a week daily email read by thousands of Westminster insiders.</p>
        <div class="mc-field-group input-group">
          <div class="col-xs-8">
            <div class="mc-field-group input-group input-group-lg">
              <input type="email" value="" name="EMAIL" class="input-lg required email blue-grey--text text--darken-4 v-input v-text-field v-text-field--mailchimp" id="mce-EMAIL" placeholder="name@domain.com">
            </div>
          </div>

          <div class="col-xs-4">
            <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="btn btn-success btn-lg button v-btn v-btn--default v-btn--block v-btn--tile py-1 my-4">
              <v-icon small class="mr-2">mdi-email</v-icon> SIGN UP</button>
          </div>

          <div class="col-xs-12">
            <!-- <p>Subscribe to the most succinct 7 days a week daily email read by thousands of Westminster insiders.</p> -->
            <input type="hidden" value="1" name="group[1][1]" id="mce-group[1]-1-0">
            <input type="hidden" value="1" name="group[1][2]" id="mce-group[1]-1-1">
          </div>
        </div>
      </div>
      <div id="mce-responses" class="clear">
        <div class="response" id="mce-error-response" style="display:none"></div>
        <div class="response" id="mce-success-response" style="display:none"></div>
      </div>
      </form>
    </div>
    <!--End mc_embed_signup-->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    category: {
      type: String,
      default: 'guido'
    }
  },

  data: () => ({
    categories: {
      guido: {
        color: 'red accent-4',
        title: 'Seen Elsewhere',
        value: 0
      },
      media: {
        color: 'grey',
        title: 'Media News List',
        value: 1
      },
      globo: {
        color: 'blue darken-3',
        title: 'Globo News',
        value: 2
      },
      gallery: {
        color: 'green darken-3',
        title: 'Sketch Round-Up',
        value: 3
      },
      gaia: {
        color: 'green accent-3',
        title: 'Green News',
        value: 4
      },
      guynews: {
        color: 'indigo darken-3',
        title: 'Seen Elsewhere',
        value: 5
      }
    }
  }),

  computed: {
    color() {
      return this.categories[this.category].color
    },
  },

  methods: {
    subscribe() {

    }
  }

}
</script>
