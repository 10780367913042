<template>
  <v-card :href="link">
    <v-card-title v-html="title"></v-card-title>
    <v-card-text v-html="content"></v-card-text>
    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'Post',

    props: [ 'title', 'content', 'link' ]
  }
</script>