import Vue from 'vue';
import Vuetify, { 
  VApp,
  VMain,
  VContent,
  VContainer,
  VRow,
  VCol,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VImg,
  VIcon,
  VBtn,
  VTabs,
  VTab,
  VList,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VChip
} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

// import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'

Vue.use(Vuetify, {
  components: { 
    VApp,
    VMain,
    VContent,
    VContainer,
    VRow,
    VCol,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VImg,
    VIcon,
    VBtn,
    VTabs,
    VTab,
    VList,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VChip
  },
});

export default new Vuetify({
  icons: {
    iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  theme: {
    themes: {
      light: {
        primary: colors.red.accent4,
      }
    },
  },
});
