<template>
  <v-navigation-drawer
    v-model="drawer"
    @input="updateDrawer(!! $event)"
    app
    right
    temporary
    fixed
  >
    <!-- primary menu items -->
    <v-list two-line dark class="py-0">
      <v-list-item
        v-for="(cat, i) in categories"
        :key="i"
        :class="cat.color"
        :href="cat.slug"
      >
        <v-list-item-action class="menu--icon">
          <img :src="cat.icon" class="svg-image" :alt="cat.name" />
        </v-list-item-action>
        <v-list-item-content class="menu--icon">
          <v-list-item-title>
            <v-img :src="cat.title" height="24" contain position="left center" eager></v-img>
          </v-list-item-title>
          <!-- <v-list-item-title class="">{{ cat.name }}</v-list-item-title> -->
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- secondary menu items -->
    <v-list dense class="text--uppercase">
      <v-list-item v-for="(item, i) in items" :key="i" :href="item.slug" :target="item.blank ? '_blank' : ''" :rel="item.blank ? 'noopener' : ''">
        <v-list-item-action><v-icon></v-icon></v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- social menu items -->
    <v-list dense class="text--uppercase">
      <v-list-item href="https://twitter.com/guidofawkes" target="_blank" rel="noopener">
        <v-list-item-action><v-icon small>mdi-twitter</v-icon></v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>Twitter</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item href="http://facebook.com/FawkesPage" target="_blank" rel="noopener">
        <v-list-item-action><v-icon small>mdi-facebook</v-icon></v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>Facebook</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item href="https://www.linkedin.com/company/guido-fawkes-blog-ggn-ltd-/" target="_blank" rel="noopener">
        <v-list-item-action><v-icon small>mdi-linkedin-box</v-icon></v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>LinkedIn</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item href="/feed" target="_blank" rel="noopener">
        <v-list-item-action><v-icon small>mdi-rss</v-icon></v-list-item-action>
        <v-list-item-content>
          <v-list-item-subtitle>RSS</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'Drawer',

    props: {
      value: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      drawer: {
        get() {
          return this.value
        },
        set(val) {
          return val
        }
      }
    },

    data: () => ({
      categories: [
        { name: 'Guido Fawkes', slug: '/', icon: require('@/assets/icon-guido.svg'), color: 'red accent-4', title: require('@/assets/menu-guido.svg') },
        { name: 'Media Guido', slug: '/media-guido', icon: require('@/assets/icon-media.svg'), color: 'grey', title: require('@/assets/menu-media.svg') },
        { name: 'Globo Guido', slug: '/globo-guido', icon: require('@/assets/icon-globo.svg'), color: 'blue darken-3', title: require('@/assets/menu-globo.svg') },
        { name: 'Gallery Guido', slug: '/gallery-guido', icon: require('@/assets/icon-gallery.svg'), color: 'green darken-3', title: require('@/assets/menu-gallery.svg') },
        { name: 'Gaia Fawkes', slug: '/gaia-fawkes', icon: require('@/assets/icon-gaia.svg'), color: 'green accent-3', title: require('@/assets/menu-gaia.svg') },
        { name: 'Guy News', slug: '/guynews', icon: require('@/assets/icon-guynews.svg'), color: 'indigo darken-3', title: require('@/assets/menu-guynews.svg') }
      ],
      items: [
        { name: 'About', slug: '/about' },
        { name: 'Advertising', slug: 'https://messagespace.co.uk', blank: true },
        // { name: 'Contact', slug: '/contact' },
        { name: 'Privacy', slug: '/privacy' },
        { name: 'Tip-Off', slug: '/tips' }
      ]
    }),

    methods: {
      updateDrawer(state) {
        this.$emit('updated', state)
      }
    }
  }
</script>