<template>
  <v-card min-height="50px" min-width="100%" class="d-flex d-md-none" v-intersect="onIntersect">
    <v-card-text></v-card-text>
  </v-card> 
</template>

<script>
export default {
  name: 'AdFooter',

  data: () => ({
    foo: 'bar',
    isIntersecting: false
  }),

  methods: {
    onIntersect (entries) { // entries, observer
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = ! entries[0].isIntersecting
    },
  }
}
</script>