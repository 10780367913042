<template>
  <v-app-bar
    app
    :color="primary"
    dark
    absolute
    flat
  >
    <!-- title -->
    <v-toolbar-title class="px-0 title--logo">
      <a :href="link">
        <img class="svg-image" :src="logo" alt="Guido Fawkes logo" />
      </a>
      <!-- <img v-else class="svg-image" :src="logo" alt="Guido Fawkes logo" /> -->
    </v-toolbar-title>
    <!-- icons -->
    <v-spacer></v-spacer>
    <v-btn icon class="d-none d-sm-flex" href="https://twitter.com/guidofawkes" target="_blank" rel="noopener">
        <v-icon>mdi-twitter</v-icon>
    </v-btn>
    <v-btn icon class="d-none d-sm-flex" href="https://facebook.com/FawkesPage" target="_blank" rel="noopener">
        <v-icon>mdi-facebook</v-icon>
    </v-btn>
    <v-btn icon class="d-none d-sm-flex" href="https://www.linkedin.com/company/guido-fawkes-blog-ggn-ltd-/" target="_blank" rel="noopener">
        <v-icon>mdi-linkedin-box</v-icon>
    </v-btn>
    <v-btn icon class="d-none d-md-flex" href="/feed" aria-label="RSS Feed">
        <v-icon>mdi-rss</v-icon>
    </v-btn>
    <v-btn icon aria-label="Search" @click.stop="openSearch()">
        <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn @click.stop="updateDrawer(!! $event)" icon aria-label="Open Menu">
        <v-icon>mdi-menu</v-icon>
    </v-btn>
    <!-- extension -->
    <template v-slot:extension>
        <v-tabs :background-color="secondary" right fixed-width hide-slider class="nav-tabs">
            <v-btn v-if="! isHome" href="/" icon small class="nav-extension__back d-none d-sm-flex">
                <v-icon dark small>mdi-home</v-icon>
            </v-btn>
            <v-tab class="nav--extension__tab" href="/tips">Tip-Off</v-tab>
            <v-tab href="tel:+442079319310" class="grey--text text--lighten-4">
                <v-icon small class="mr-2">mdi-phone</v-icon> 020 7931 9310
            </v-tab>
            <v-tab href="mailto:team@order-order.com" class="grey--text text--lighten-4">
                <v-icon small class="mr-2">mdi-email</v-icon>team@order-order.com
            </v-tab>
        </v-tabs>
    </template>
    <v-dialog
      eager
      v-model="dialog"
      max-width="290"
      fullscreen hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar flat color="transparent">
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container fill-height style="height: calc( 100vh - 64px )">
          <v-row align="center" justify="center">
            <v-col cols="11" sm="10" md="9" lg="8" class="text-center pb-12">
              <v-text-field autofocus ref="searchfield" v-model="search" label="Search" append-icon="mdi-magnify" @click:append="submitSearch" @keydown.enter="submitSearch" clearable></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'Toolbar',

    props: {
      link: {
        type: String,
        default: '/'
      },
      logo: {
        type: String,
        default: ''
      },
      primary: {
        type: String,
        default: 'red accent-4'
      },
      secondary: {
        type: String,
        default: 'red darken-4'
      },
      value: {
        type: Boolean,
        default: false
      },
      isHome: {
        // type: Boolean,
        default: false
      }
    },

    computed: {
      drawer: {
        get() {
          return this.value
        },
        set(val) {
          return val
        }
      },
    },

    data: () => ({
      dialog: false,
      search: ''
    }),

    methods: {
      updateDrawer(state) {
        this.$emit('updated', state)
      },
      openSearch() {
        this.dialog = true
        // this.$refs.searchField.$el.focus()
        let self = this
        setTimeout(function() {self.$nextTick(self.$refs.searchfield.focus)}, 200)
      },
      submitSearch() {
        window.location.href = '/?s=' + this.search
      }
    }
  }
</script>