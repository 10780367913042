<template>
  <v-card color="amber lighten-5 mb-6 v-card--sidebar seen-elsewhere">
    <v-card-title :class="color" class="white--text font--anton py-3">
      <!-- <v-icon dark class="mr-2">mdi-eye</v-icon> -->
      {{ title }}
    </v-card-title>
    <v-tabs v-model="tab" :show-arrows="false" dark grow hide-slider @change="doSomething()">
      <v-tab v-for="(cat, i) in categories" :key="cat.value" :id="cat.value" :class="'tab-'+i + ' ' + cat.color">
        <img :src="cat.icon" class="svg-image" :alt="cat.title" />
      </v-tab>
    </v-tabs>
    <transition name="fade-transition" mode="out-in" appear>
    <v-list two-line tile style="border-radius: 0;" class="amber lighten-5 py-0 v-list--striped" v-show="list.length > 0">
      <v-list-item v-for="(item, i) in list" :key="i" :href="item.newslink_url" target="_blank" rel="noopener">
        <v-list-item-content>
          <v-list-item-title v-html="item.title.rendered"></v-list-item-title>
          <v-list-item-subtitle v-if="item.newslink_author" v-html="item.newslink_author"></v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="item.newslink_publication"><i>{{ item.newslink_publication }}</i></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    </transition>
    <v-list two-line class="amber lighten-5 v-list--striped" v-show="list.length == 0">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>loading...</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: 'SeenElsewhere',

    props: {
      category: {
        type: String,
        default: 'guido'
      },
      value: {
        type: Number,
        default: 0
      },
      per_page: {
        type: Number,
        default: 8
      }
    },

    data: () => ({
      tab: 0,
      categories: {
        guido: {
          color: 'red accent-4',
          title: 'Seen Elsewhere',
          value: 0,
          icon: require('@/assets/icon-guido.svg')
        },
        media: {
          color: 'grey',
          title: 'Media News List',
          value: 1,
          icon: require('@/assets/icon-media.svg')
        },
        globo: {
          color: 'blue darken-3',
          title: 'Global News',
          value: 2,
          icon: require('@/assets/icon-globo.svg')
        },
        gallery: {
          color: 'green darken-3',
          title: 'Sketch Round-Up',
          value: 3,
          icon: require('@/assets/icon-gallery.svg')
        },
        gaia: {
          color: 'green accent-3',
          title: 'Green News',
          value: 4,
          icon: require('@/assets/icon-gaia.svg')
        },
        guynews: {
          color: 'indigo darken-3',
          title: 'Watch',
          value: 5,
          icon: require('@/assets/icon-guynews.svg')
        }
      },
      items: [
        {
          title: `Billionaires: Sign of a fair society`,
          source: `ConservativeHome`
        },
        {
          title: `Labour would crush civil society`,
          source: `Roger Scruton`
        },
        {
          title: `Candidate found he was being stood down from Guido`,
          source: `Northants Chronicle`
        },
        {
          title: `Ones to watch in the new generation of Tory MPs`,
          source: `Capx`
        },
        {
          title: `How hard left siezed Labour`,
          source: `UnHerd`
        },
        {
          title: `Why Boris should be Tory leader`,
          source: `Nick Boles`
        },
        {
          title: `Holistic approach to mental health`,
          source: `Jessica Marchant`
        },
        {
          title: `You don't have to agree 100% to vote for 'em`,
          source: `Mark Wallace`
        }
      ],
      list: [],
      lists: []
    }),

    computed: {
      // color() {
      //   return this.categories[this.category].color
      // },
      color() {
        switch (this.tab) {
          case 1:
            return 'grey'
          case 2:
            return 'blue darken-3'
          case 3:
            return 'green darken-3'
          case 4:
            return 'green accent-3'
          case 5:
            return 'indigo darken-3'
          default:
            return 'red accent-4'
        }
      },
      // title() {
      //   return this.categories[this.category].title
      // }
      title() {
        switch (this.tab) {
          case 1:
            return 'Media News List'
          case 2:
            return 'Global News'
          case 3:
            return 'Sketch Round-Up'
          case 4:
            return 'Green News'
          case 5:
            return 'In Case You Missed It'
          default:
            return 'Seen Elsewhere'
        }
      },
      cat() {
        switch (this.tab) {
          case 1:
            return '8,15' //'15'
          case 2:
            return '2,21' //'21'
          case 3:
            return '4,29' //'29'
          case 4:
            return '3,23' //'23'
          case 5:
            return '6'
          default:
            return '5'
        }
      }
    },

    methods: {
      doSomething() {
        if( this.lists[this.cat] ) {
          this.list = this.lists[this.cat]
        } 
        else {
          this.getList();
        }
        // alert('done something');
      },
      getList() {
        this.$axios.get('/wp-json/wp/v2/seen-elsewhere?categories='+this.cat+'&filter[orderby]=date&order=desc&per_page='+this.per_page)
        .then(response => {
          this.lists[this.cat] = response.data
          // this.list = response.data
          this.list = this.lists[this.cat]
        })
      }
    },

    created() {
      this.tab = this.categories[this.category].value
    },

    mounted() {
      this.getList()
    }
  }
</script>